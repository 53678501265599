<template>
    <Splide
        class="slider"
        v-if="items.length !== 0 && items[0] !== ''"
        :options="options"
        >
        <template v-for="(item, index) in items">
            <SplideSlide :key=" 'picture-' + index" style="height: auto">
                <!-- <img :src="imageSrc(item)" style="max-width: 500px;"/> -->
                <div  class="slider__item" :style="`background-image: url(${imageSrc(item)})`" />
            </SplideSlide>
        </template>
    </Splide>
</template>

<script>
    import { imageSrc } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { mapState } from 'vuex';

    export default {
        name: 'Slider',
        components: {
            Splide,
            SplideSlide,
        },
        props: {
            items: { 
                type: Array, 
                default: () => []
            },
            options: {
                type: Object,
                default: () => {}
            },
            numberOfImages: {
                type: [Number, String],
                default: 0
            },
        },
        data: () => ({
            imageSrc,
            raiting: false
        }),
        computed: {
        },
        // methods: {
        // }
    }
</script>

<style lang="scss">
.slider {
    &__item {
        width: 100%;
        height: 440px;
        background-size: cover;
        background-position: center;
    }
}
</style>
