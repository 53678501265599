<template>
    <v-menu
        ref="menu"
        v-model="menu"
        class="base-datepicker__menu-block"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px">
        <template #activator=" { on }">
            <div class="base-datepicker-field__container" v-on="on">
                <v-text-field
                    class="base-datepicker-field"
                    readonly
                    outlined
                    :value="dateText"
                    :label="label">
                    <template #append>
                        <img
                            src="../../assets/Calendar3.svg"
                            class="prepend-icon" />
                    </template>
                </v-text-field>
            </div>
            <div class="v-messages error--text" role="alert">
                <div class="v-messages__wrapper">
                    <div class="v-messages__message"> {{ errorMessages }}</div>
                </div>
            </div>
        </template>
        <v-date-picker
            :type="type"
            :value="value"
            :max="max"
            :min="min"
            no-title
            color="#E2231A"
            :locale="lang === 'ru' ? 'ru-RU' : 'en-US'"
            @input="updateDate" />
    </v-menu>
</template>

<script>
    import { mapState } from 'vuex';
    import moment from '@/plugins/moment'
    export default {
        props: {
            type: {
                type: String,
                default: 'date',
            },
            value: {
                type: String,
                default: '',
            },
            max: {
                type: String,
                default: '',
            },
            min: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            errorMessages: {
                type: String,
                default: '',
            },
        },
        data: () => ( {
            menu: false,
            modal: false,
            menu2: false,
        }),
        computed: {
            ...mapState('settings', ['lang']),
            dateText() {
                if (this.value) {
                    let newValue = [];
                    newValue.push(moment(this.value).format('DD.MM.YYYY'));
                    return newValue    
                } 
                return '';
            }
        },
        methods: {
            updateDate(value) {
                this.menu = false;
                this.$emit('change', value);
            },
        },
    };
</script>
<style lang="scss">
.base-datepicker-field {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #273155;
    // height: 56px;
    margin-bottom: 16px !important;
    legend {
        width: 0 !important;
        height: 0 !important;
    }
    &.theme--light.v-input input {
        position:absolute;
        bottom: 10px;
        left: 8px;
        padding: 0;
    }
    .v-messages {
    min-height: 0 !important;
    }
    .v-text-field__details {
        min-height: 0 !important;
        margin-bottom: 0 !important;
    }

    .v-input__slot {
        border-radius: 10px;
        box-shadow: rgba(30, 28, 46, 0.03);
        background: #fff !important;
        margin-bottom: 0;
    }
    .v-messages {
        min-height: 0 !important;
    }
    &.v-messages .error--text {
        min-height: 0 !important;
    }
    &.v-text-field--outlined fieldset {
        border: 1px solid #EBEBEB;
    }
    &.v-text-field--outlined.v-input--is-focused fieldset,
    &.v-text-field--outlined.v-input--has-state fieldset {
        border: 1px solid #EBEBEB;
    }
    &.v-text-field--outlined.error--text.v-input--is-focused fieldset,
    &.v-text-field--outlined.error--text.v-input--has-state fieldset {
        border: 1px solid #ff5252;
    }

    .theme--light.v-label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        margin-left: 8px;
        color: #A9AEB2;
        &.v-label--active {
            position: absolute;
            top: 35px;
        }
    }
}

.v-messages {
    min-height: 0 !important;
}
.v-text-field__details {
    min-height: 0 !important;
    min-width: 0 !important;
}
</style>

