<template>
    <div class="right-info-block" v-if="bloggerTrip && bloggerTrip.trip && translates && translates[langUrl]">
        <!-- <DateSelect
            :items="dateSelectItems"
            item-text="label"
            item-value="value"
            :value="0"
            @change="setActiveArrival"
        /> -->
        <BaseDatePicker
            :label="translates[langUrl].tourDate[lang]"
            :value="bloggerTrip.start"
            @change="updateDate"
        />
        <div class="right-info-block__prices__info no-border">
            <div class="right-info-block__prices__info__text">
                {{ translates[langUrl].tourDate[lang] }}
            </div>
            <div class="right-info-block__prices__info__value">
                {{bloggerTrip.duration}}
            </div>
        </div>
        <div class="right-info-block__prices__info no-border">
            <div class="right-info-block__prices__info__text">
                {{ translates[langUrl].tourPrice[lang] }}
            </div>
            <div class="right-info-block__prices__info__value">
                <!-- {{ bloggerTrip.price }} ₽ -->
                <span v-html="priceFormated(bloggerTrip.price, currency)"></span>
            </div>
        </div>
        <template v-if="type === 'blogger'">
            <div class="right-info-block__prices__info">
                <div class="right-info-block__prices__info__text">
                    <div class="right-info-block__prices__info__text bloggerAward">{{ translates[langUrl].bloggerAward[lang] }}</div>
                    <div class="right-info-block__prices__info__comment">{{ translates[langUrl].writeYourAward[lang] }}</div>
                </div>
                <!-- <div class="right-info-block__prices__info__text__value">
                    20 000 ₽
                </div> -->
                <AwardTextField 
                    v-model="bloggerTrip.bloggerAward"
                />
            </div>
            <div class="right-info-block__prices__info no-border">
                <div class="right-info-block__prices__info__text">
                    {{ translates[langUrl].totalPrice[lang] }}
                </div>
                <div class="right-info-block__prices__info__value weight">
                    <span v-html="priceFormated(priceWithAward, currency)"></span>
                </div>
            </div>
            <div class="right-info-block__prices__info travelers">
                <div class="right-info-block__prices__info__text">
                    {{ translates[langUrl].bloggerPlaces[lang] }}
                </div>
                <BaseCounter
                    :value="bloggerTrip.bloggerPlaces"
                    :max-value="+bloggerTrip.trip.groupSizeTo || 100"
                    @change="updateTraveler"
                />
            </div>
            <div class="right-info-block__prices__info no-border">
                <div class="right-info-block__prices__info__text">
                    {{ translates[langUrl].bloggerSelled[lang] }}
                </div>
                <AwardTextField 
                    v-model="bloggerTrip.bloggerSelled"
                    :suffix="''"
                />
            </div>
            <div class="right-info-block__prices__info no-border">
                <div class="right-info-block__prices__info__text">
                    {{ translates[langUrl].groupReward[lang] }}
                </div>
                <div class="right-info-block__prices__info__value big">
                    <!-- {{ priceFormated(cost(), currency) }} ₽ -->
                    <span v-html="priceFormated(totalAward, currency)"></span>
                </div>
            </div>
            <div class="right-info-block__prices__info">
                <BaseButton
                    style="width: 100%;"
                    @click="openWorkshops"
                >{{ translates[langUrl].button_workshops[lang] }}</BaseButton>
            </div>
            <div class="right-info-block__prices__info no-border" v-if="bloggerTrip.status !== 'active'">
                <div class="right-info-block__prices__info__text" style="width: 100%">
                    <BaseButton
                        style="width: 100%;"
                        class="button-fill"
                        @click="save"
                        >{{ translates[langUrl].button_save[lang] }}</BaseButton>
                    <!-- <div class="right-info-block__prices__info__comment right-info-block__prices__info__comment__button">{{ translates[langUrl].commission[lang] }}</div> -->
                </div>
            </div>
            <div class="right-info-block__prices__info no-border" v-if="bloggerTrip.status === 'active'">
                <div class="right-info-block__prices__info__text" style="width: 100%">
                    <BaseButton
                        style="width: 100%;"
                        class="button-fill"
                        @click="saveChanges"
                        >{{ translates[langUrl].button_saveChanges[lang] }}</BaseButton>
                    <!-- <div class="right-info-block__prices__info__comment right-info-block__prices__info__comment__button">{{ translates[langUrl].commission[lang] }}</div> -->
                </div>
            </div>
            <div class="right-info-block__prices__info no-border" v-if="bloggerTrip.status !== 'active'">
                <div class="right-info-block__prices__info__text" style="width: 100%">
                    <BaseButton
                        style="width: 100%;"
                        @click="openDeclinePopup"
                        >{{ translates[langUrl].button_decline[lang] }}</BaseButton>
                </div>
            </div>
            <div class="right-info-block__prices__info no-border" v-if="bloggerTrip.status === 'active'">
                <div class="right-info-block__prices__info__text" style="width: 100%">
                    <BaseButton
                        style="width: 100%;"
                        @click="openTour(bloggerTrip.trip.code)"
                        >{{ translates[langUrl].button_view[lang] }}</BaseButton>
                </div>
            </div>
        </template>
        
        <v-dialog
            v-model="declinePopup"
            max-width="500px"
        >
        <div class="right-info-block__decline-poup">
            <div class="right-info-block__decline-poup__title">{{ translates[langUrl].popup_decline_title[lang] }}</div>
            <div class="right-info-block__decline-poup__close" @click="openDeclinePopup"></div>
            <div class="right-info-block__decline-poup__text">{{ translates[langUrl].popup_decline_text[lang] }}</div>
            <BaseButton @click="decline">{{ translates[langUrl].popup_decline_button[lang] }}</BaseButton>
        </div>
        </v-dialog>
    </div>
</template>

<script>
    import DateSelect from './DateSelect.vue'
    import BaseButton from '../BaseButton.vue'
    import BaseCounter from '../BaseCounter.vue'
    import BaseTextField from '../BaseTextField.vue'
    import BaseDatePicker from '../BaseDatePicker.vue'
    import AwardTextField from '../AwardTextField.vue'

    import { imageSrc, priceFormated, isMobile } from '@/helpers';
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BLOGGER_TRIP_STATUS } from '@/vars';
    import moment from '@/plugins/moment'

    export default {
        name: 'Calculator',
        components: {
            DateSelect,
            BaseButton,
            BaseCounter,
            AwardTextField,
            BaseTextField,
            BaseDatePicker
        },
        props: {
            type: {
                type: String,
                default: 'blogger'
            }
        },
        data: () => ({
            BLOGGER_TRIP_STATUS,
            imageSrc,
            bloggerAward: 0,
            priceFormated,
            lastMove: null,
            isMobile: false,
            declinePopup: false,
            langUrl: 'Calculator'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('bloggerTrips', {
                bloggerTrip: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => {
                    return state.entities.filter(item => { return item.status !== BOOKING_STATUS.CANCELED })
                }
            }),
            expert() {
                return this.bloggerTrip.blogger;
            },
            dates() {
                if(this.bloggerTrip && this.bloggerTrip.start && this.bloggerTrip.finish) {
                    return `${ moment(this.bloggerTrip.start).format('DD MMM') } - ${ moment(this.bloggerTrip.finish).format('DD MMM') }`;
                }
                return '';
            },
            totalAward() {
                return +this.bloggerTrip.bloggerPlaces * +this.bloggerTrip.bloggerAward;
            },
            priceWithAward() {
                return this.bloggerTrip.bloggerPlaces * (+this.bloggerTrip.price + +this.bloggerTrip.bloggerAward);
            },
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted() {
            if(!this.bloggerTrip.bloggerAward) {
                this.bloggerTrip.bloggerAward = 0;
            }
            // store.dispatch( 'bookings/fetch', {} );
        },
        methods: {
            onResize() {
                this.isMobile = !!isMobile();
            },
            updateTraveler(value) {
                this.bloggerTrip.bloggerPlaces = value;
            },
            async save() {
                store.commit('bloggerTrips/SET_ENTITY', {
                    ...this.bloggerTrip,
                    status: BLOGGER_TRIP_STATUS.ACTIVE
                });
                await store.dispatch('bloggerTrips/save');
                await this.$router.push({ name: 'blogger-trips-my' });
            },
            updateDate(value) {
                console.log(value);
                this.bloggerTrip.start = value;
            },
            async saveChanges() {
                this.bloggerTrip.finish = moment(this.bloggerTrip.start).add(+(this.bloggerTrip.duration - 1), 'days');
                store.commit('bloggerTrips/SET_ENTITY', {
                    ...this.bloggerTrip,
                    status: BLOGGER_TRIP_STATUS.ACTIVE
                });
                await store.dispatch('bloggerTrips/save');
                await store.dispatch('trips/save');
                await this.$router.push({ name: 'blogger-trips-my' });
            },
            openDeclinePopup() {
                this.declinePopup = !this.declinePopup
            },
            async decline() {
                store.commit('bloggerTrips/SET_ENTITY', {
                    ...this.bloggerTrip,
                    status: BLOGGER_TRIP_STATUS.DECLINED
                });
                await store.dispatch('bloggerTrips/save');
                await this.$router.push({ name: 'blogger-trips-my' });
            },
            openWorkshops() {
                this.$emit('openWorkshopsPopup')
            },
            openTour(code) {
                this.$router.push({ name: 'trip', params: { code } })
            }
        }
    }

</script>

<style lang="scss">
.right-info-block {
    position: relative;
    max-width: 420px;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 32px;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    z-index: 5;
    &__prices {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 6px 16px;
        border-bottom: 1px solid #EBEBEB;
        // @media all and (max-width: 768px) {
        //     flex-direction: column;
        //     justify-content: flex-start;
        // }
        &__info {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            padding: 17px 0;
            border-top: 1px solid #EBEBEB;
            display: flex;
            justify-content: space-between;
            &.no-border {
                border: none;
            }
            &__text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #A1A4B1;
                &.bloggerAward {
                    // margin-top: 5px;
                    margin-bottom: 4px;
                }
            }
            &__value {
                color: #273155;
                &.weight {
                    font-weight: 700;
                }
                &.big {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            &__comment {
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                @media all and (max-width: 768px) {
                    margin-bottom: 4px;
                }

                &__button {
                    margin-top: 8px;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                }
            }
        }
    }
    &__organizer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0;
        &__text {
            color: #3A4256;
            margin-left: 10px;
            &__name {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin: auto 0;
                text-align: center;
            }
        }
        &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            // background: #EBEBEB;
        }
    }
    &__decline-poup {
        max-width: 500px;
        max-height: 800px;
        width: 100%;
        padding: 30px 40px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        flex-direction: column;
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__title {
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
            color: #000000;
            margin: 0 auto 12px;
            text-align: center;
        }
        &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #A9AEB2;
            margin: 0 auto 5px;
            max-width: 500px;
            width: 100%;
            text-align: left;
            &__accent {
                font-weight: 500;
                color: black;
            }
        }
    }
}
</style>